/*
Kaynak kodu incelemek isteyenler için açık bırakılmıştır.
Burada kullanılan tekniklerin hiçbiri "best practice" değildir.
Tüm uygulama yaklaşık 2 saat gibi bir sürede hazırlanmıştır.
Sevgiler
*/

import { useEffect, useState } from "react";
import PocketBase from "pocketbase";

import "./style.css";

const pb = new PocketBase("https://itiraflarim.pockethost.io");

function App() {
  const [showForm, setShowForm] = useState(false);
  const [facts, setFacts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("all");

  useEffect(() => {
    pb.realtime.subscribe("facts", function (e) {
      let x = facts.filter((item) => item.id !== e.record.id);
      setFacts(
        [e.record, ...x].sort(function (a, b) {
          return new Date(b.created) - new Date(a.created);
        })
      );
    });
    return () => {
      pb.realtime.unsubscribe();
    };
  });

  useEffect(
    function () {
      async function getFacts() {
        let facts = [];
        if (currentCategory !== "all") {
          facts = await pb.collection("facts").getFullList({
            sort: "-created",
            expand: "category",
            filter: "(category='" + currentCategory + "')",
          });
        } else {
          facts = await pb.collection("facts").getFullList({
            sort: "-created",
            expand: "category",
          });
        }

        setFacts(facts);
      }
      async function getCategories() {
        const categories = await pb.collection("categories").getFullList({
          sort: "+name",
        });

        setCategories(categories);
      }
      setIsLoading(true);
      getCategories();
      getFacts();
      setIsLoading(false);
    },
    [currentCategory]
  );

  return (
    <>
      <Header showForm={showForm} setShowForm={setShowForm} />
      {showForm ? (
        <NewFactForm
          setFacts={setFacts}
          setShowForm={setShowForm}
          categories={categories}
        />
      ) : null}

      <main className="main">
        <CategoryFilter
          setCurrentCategory={setCurrentCategory}
          categories={categories}
        />
        {isLoading ? (
          <Loader />
        ) : (
          <FactList facts={facts} setFacts={setFacts} categories={categories} />
        )}
      </main>
    </>
  );
}

function Loader() {
  return <p className="message text-dark">Yükleniyor...</p>;
}

function Header({ showForm, setShowForm }) {
  const appTitle = "İTİRAFLARIM";

  return (
    <header className="header">
      <h1>{appTitle}</h1>

      <button
        className="btn btn-large btn-open"
        onClick={() => setShowForm((show) => !show)}
      >
        {showForm ? "Kapat" : "İtiraf Et"}
      </button>
    </header>
  );
}

function NewFactForm({ setFacts, setShowForm, categories }) {
  const [text, setText] = useState("");
  // Fixed in a video text overlay
  // const [source, setSource] = useState("");
  const [category, setCategory] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const textLength = text.length;

  async function handleSubmit(e) {
    // 1. Prevent browser reload
    e.preventDefault();

    // 2. Check if data is valid. If so, create a new fact
    // if (text && isValidHttpUrl(source) && category && textLength <= 200) {
    if (text && category && textLength <= 1000) {
      // 3. Upload fact to Pocketbase and receive the new fact object
      setIsUploading(true);
      const data = {
        text: text,
        source: null,
        votesInteresting: 0,
        votesMindblowing: 0,
        votesFalse: 0,
        category: category,
      };

      const newFact = await pb.collection("facts").create(data);

      setIsUploading(false);

      // 4. Add the new fact to the UI: add the fact to state
      if (newFact.id) {
        console.log(newFact);

        // setFacts((facts) => [newFact, ...facts]);
      }

      // 5. Reset input fields
      setText("");
      // setSource("");
      setCategory("");

      // 6. Close the form
      setShowForm(false);
    }
  }

  return (
    <form className="fact-form" onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="İçini dök..."
        value={text}
        onChange={(e) => setText(e.target.value)}
        disabled={isUploading}
      />
      <span>{1000 - textLength}</span>
      {/* <input
        value={source}
        type="text"
        placeholder="Trustworthy source..."
        onChange={(e) => setSource(e.target.value)}
        disabled={isUploading}
      /> */}
      <select
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        disabled={isUploading}
      >
        <option value="">Konu seç:</option>
        {categories.map((cat) => (
          <option key={cat.name} value={cat.id}>
            {cat.name.toUpperCase()}
          </option>
        ))}
      </select>
      <button className="btn btn-large" disabled={isUploading}>
        Gönder
      </button>
    </form>
  );
}

function CategoryFilter({ setCurrentCategory, categories }) {
  return (
    <aside>
      <ul>
        <li className="category">
          <button
            className="btn btn-all-categories"
            onClick={() => setCurrentCategory("all")}
          >
            Tümü
          </button>
        </li>

        {categories.map((cat) => (
          <li key={cat.id} value={cat.id} className="category">
            <button
              className="btn btn-category"
              style={{ backgroundColor: cat.color }}
              onClick={() => setCurrentCategory(cat.id)}
            >
              {cat.name}
            </button>
          </li>
        ))}
      </ul>
    </aside>
  );
}

function FactList({ facts, setFacts, categories }) {
  if (facts.length === 0) return <p className="message text-dark">Yükleniyor...</p>;

  return (
    <section>
      <p className="realtime-info">↻ İçerikler otomatik güncellenir.</p>
      <ul className="facts-list">
        {facts.map((fact) =>
          !fact.isHidden ? (
            <Fact
              key={fact.id}
              fact={fact}
              setFacts={setFacts}
              categories={categories}
            />
          ) : (
            ""
          )
        )}
      </ul>
    </section>
  );
}

function Fact({ fact, setFacts, categories }) {
  const [isUpdating, setIsUpdating] = useState(false);
  const isDisputed =
    fact.votesInteresting + fact.votesMindblowing < fact.votesFalse;

  async function handleVote(columnName) {
    setIsUpdating(true);
    let updatedData = fact;
    updatedData[columnName] = fact[columnName] + 1;
    const record = await pb.collection("facts").update(fact.id, updatedData);
    setIsUpdating(false);
    if (record.id)
      setFacts((facts) => facts.map((f) => (f.id === fact.id ? record : f)));
  }

  return (
    <li className="fact">
      <p>
        {isDisputed ? null : null}
        {fact.text}
        {/* <a className="source" href={fact.source} target="_blank">
          (Source)
        </a> */}
      </p>

      <div className="row">
        <div className="col-1">
          <span
            className="tag"
            style={{
              backgroundColor: categories.find(
                (cat) => cat.id === fact.category
              ).color,
            }}
          >
            {categories.find((cat) => cat.id === fact.category).name}
          </span>
        </div>
        <div className="col-2">
          <div className="vote-buttons">
            <button
              onClick={() => handleVote("votesInteresting")}
              disabled={isUpdating}
            >
              👍 {fact.votesInteresting}
            </button>
            <button
              onClick={() => handleVote("votesMindblowing")}
              disabled={isUpdating}
            >
              😶 {fact.votesMindblowing}
            </button>
            <button
              onClick={() => handleVote("votesFalse")}
              disabled={isUpdating}
            >
              🔥 {fact.votesFalse}
            </button>
          </div>
        </div>
      </div>
    </li>
  );
}

export default App;
